<template>
  <b-card no-body>
    <b-overlay :show="loading">
      <b-card-body>
        <b-card-title>
          <b-row>
            <b-col><div>Kategori Paket Try Out</div></b-col>
            <b-col>
              <div align="right">
                <b-button
                  variant="outline-warning"
                  v-b-modal.modal-sm
                  :to="{ name: `admin-paket-tryout` }"
                  class="btn-icon mr-1"
                >
                  <feather-icon icon="ListIcon" class="mr-25" />Paket Try Out</b-button
                >
                <!-- <b-button
                  variant="outline-primary"
                  v-b-modal.modal-sm
                  @click="ModalShow()"
                  class="btn-icon"
                >
                  <feather-icon icon="PlusIcon" class="mr-25" />Tambah</b-button
                > -->
              </div>
              <b-modal
                v-model="Modal"
                id="modal-tambah"
                centered
                size="sm"
                :title="titleModal"
              >
                <b-card-text>
                  <b-form>
                    <b-form-group label="Kategori Paket Try Out" label-for="kategori">
                      <b-form-input
                        id="kategori"
                        v-model="form.name"
                        placeholder="Kategori Paket Try Out"
                      />
                    </b-form-group>
                    <b-form-group label="Warna" label-for="warna">
                      <b-form-radio-group
                        id="warna"
                        v-model="form.color"
                        :options="optWarna"
                        class="demo-inline-spacing mb-1"
                        value-field="value"
                        text-field="text"
                        disabled-field="disabled"
                      />
                    </b-form-group>
                  </b-form>
                  <b-card-text class="mt-1 mb-0">
                    Warna:
                    <b-badge :variant="form.color">
                      {{ form.color }}
                    </b-badge>
                  </b-card-text>
                </b-card-text>

                <template #modal-footer>
                  <div class="w-100">
                    <p class="float-left mb-0"></p>

                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="outline-success"
                      class="mr-1 btn-icon"
                      @click.prevent="Tambah"
                    >
                      <feather-icon icon="SaveIcon" class="mr-25" />{{
                        label || "Tambah"
                      }}
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-primary"
                      class="float-right btn-icon"
                      @click.preveent="tutupModal"
                    >
                      <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                    </b-button>
                  </div>
                </template>
              </b-modal>
            </b-col></b-row
          >
        </b-card-title>
        <b-card-text class="blog-content-truncate">
          <b-row>
            <b-col md="3" sm="4" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">Per page</label>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                  class="w-50"
                />
              </b-form-group>
            </b-col>
            <b-col md="4" sm="8" class="my-1">
              <b-form-group
                label="Sort"
                label-cols-sm="2"
                label-align-sm="right"
                label-size="sm"
                label-for="sortBySelect"
                class="mb-0"
              >
                <b-input-group size="sm">
                  <b-form-select
                    id="sortBySelect"
                    v-model="sortBy"
                    :options="sortOptions"
                    class="w-50"
                  >
                    <template v-slot:first>
                      <option value="">-- none --</option>
                    </template>
                  </b-form-select>
                  <b-form-select
                    v-model="sortDesc"
                    size="sm"
                    :disabled="!sortBy"
                    class="w-30"
                  >
                    <option :value="false">Asc</option>
                    <option :value="true">Desc</option>
                  </b-form-select>
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="5" class="my-1">
              <b-form-group label-for="filterInput" class="mb-0">
                <b-input-group size="sm">
                  <b-form-input
                    id="filterInput"
                    v-model="filter"
                    type="search"
                    placeholder="Search......"
                  />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> Clear </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
            <!-- <b-col md="2" class="my-1">
            <b-button variant="primary" size="sm"
              ><feather-icon icon="TrashIcon" />Multiple Delete</b-button
            >
          </b-col> -->

            <b-col cols="12">
              <b-table
                striped
                small
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                :fields="fields"
                :items="katpaketData"
              >
                <!-- A virtual column -->
                <template #cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <!-- A custom formatted column -->
                <template #cell(action)="row">
                  <b-button
                    variant="flat-info"
                    class="btn-icon rounded-circle"
                    @click="ModalUbah(row.item)"
                  >
                    <feather-icon icon="EditIcon"
                  /></b-button>
                  <!-- <b-button variant="outline-danger" class="btn-icon ml-1" @click="ModalHapus(row.item)"> <feather-icon icon="TrashIcon" /></b-button> -->
                </template>
                <!-- A custom formatted column -->
                <template #cell(Popularity)="data">
                  <b-progress
                    :value="data.value.value"
                    max="100"
                    :variant="data.value.variant"
                    striped
                  />
                </template>

                <template #cell(color)="data">
                  <b-badge :variant="data.item.color">
                    {{ data.item.color }}
                  </b-badge>
                </template>

                <!-- A virtual composite column -->
                <template #cell(price)="data">
                  {{ "$" + data.value }}
                </template>

                <!-- Optional default data cell scoped slot -->
                <template #cell()="data">
                  {{ data.value }}
                </template>
              </b-table>
              <!-- <b-pagination
          v-if="record !== 0"
          :total="Math.ceil((total * 1) / itemsPerPage)"
          v-model="currentPage"
          @change="staf()"
        /> -->
              <!-- :prev-icon="isRTL ? 'arrow_forward' : 'arrow_back'"
          :next-icon="isRTL ? 'arrow_back' : 'arrow_forward'" -->

              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="center"
                size="sm"
                class="my-0"
              />
            </b-col>
          </b-row>
        </b-card-text>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    ToastificationContent,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      katpaketData: [],
      form: {
        id: null,
        name: null,
        color: null,
      },
      //Modal
      titleModal: null,
      Modal: false,
      ModalEdit: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Kategori Paket Try Out", sortable: true },
        { key: "color", label: "Warna", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optWarna: [
        { value: "primary", text: "primary" },
        { value: "info", text: "info" },
        { value: "secondary", text: "secondary" },
        { value: "warning", text: "warning" },
        { value: "success", text: "success" },
        { value: "light-primary", text: "light-primary" },
        { value: "light-info", text: "light-info" },
        { value: "light-secondary", text: "light-secondary" },
        { value: "light-warning", text: "light-warning" },
        { value: "light-success", text: "light-success" },
      ],
      optFilter: [
        { id: "name", value: "Kategori Paket Try Out" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Kategori Paket Try Out",
      search: null,
      idd: null,
      loading: false,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetForm() {
      this.form = {
        id: null,
        name: null,
        color: null,
      };
    },
    tutupModal() {
      this.Modal = false;
      this.getData();
      this.resetForm();
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.titleModal = "Tambah Kategori Paket Try Out";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalUbah(item) {
      this.id = item.id;
      this.form = item;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Kategori Paket Try Out";
      this.Modal = true;
      // this.ModalEdit = true;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 2; //soft delete
          this.$store
            .dispatch("paketTryout/CUDKategoriPaket", [data])
            .then(() => {
              this.getData();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.form.name == null || this.form.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        name: this.form.name,
        color: this.form.color,
      };

      if (this.id) {
        payload.id = this.id;
      }

      this.$store
        .dispatch("paketTryout/CUDKategoriPaket", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.form.name = null;
          this.Modal = false;
          // this.ModalEdit = false;
          this.getData();
          this.displaySuccess({
            text: "Kategori Paket Try Out berhasil di" + this.activeAction,
          });
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getData() {
      this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          this.loading = false;
          let katpaketData = response.data.data;
          this.katpaketData = katpaketData;
          this.totalRows = this.katpaketData.length;
        })
        .catch((error) => {
          this.loading = false;
          this.$root.$emit("errorData", error);
        })
        .finally(() => {
          this.selected = [];
        });
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
